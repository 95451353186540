import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import httpService from "../shared/services/http.service";

export type TSaveParam = {
  originalName: string;
  givenName: string;
};

const useSaveParams = () => {
  const [somethingWrong, setSomethingWrong] = useState(false);
  const location = useLocation();
  console.log(somethingWrong);

  const paramsToSave: TSaveParam[] = [
    { originalName: "s_t", givenName: "authToken" },
    { originalName: "m", givenName: "mParam" },
    { originalName: "w", givenName: "workspace_id" },
  ];

  const getCurrentUser = async (workspace_id: string, authToken: string) => {
    try {
      const data = await httpService.post(
        "dashboard/session/start",
        {},
        { workspace_id: workspace_id },
        { Authorization: authToken }
      );
      localStorage.setItem("current_user", JSON.stringify(data));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);

    const storedToken = localStorage.getItem("authToken") || "";
    const currentUser =
      JSON.parse(localStorage.getItem("current_user")) || null;

    if (storedToken) {
      if (urlSearchParams.get("s_t") !== storedToken)
        return setSomethingWrong(true);
    } else if (!storedToken) {
      paramsToSave.forEach((param) => {
        const paramValue = urlSearchParams.get(param.originalName);
        if (paramValue) {
          localStorage.setItem(param.givenName, paramValue);
        }
      });
    }

    // If currentUser is null or undefined
    if (!currentUser) {
      let workspaceId = "";
      let authToken = "";

      // Extract the parameter values from urlSearchParams
      paramsToSave.forEach((param) => {
        const paramValue = urlSearchParams.get(param.originalName);
        if (paramValue) {
          if (param.originalName === "w") {
            workspaceId = paramValue;
          } else if (param.originalName === "s_t") {
            authToken = paramValue;
          }
        }
      });

      // Call getCurrentUser with the extracted parameters
      getCurrentUser(workspaceId, authToken);
    }
  }, [location.search]);

  return null;
};

export default useSaveParams;
