import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { CSSProperties, memo, useCallback, useState } from "react";

interface MapProps {
  containerStyle: CSSProperties;
  center?: any;
  zoom?: number;
  children: any;
  mapRef?: any;
  onCenter?: any;
}

const DEFAULT_CONTAINER_STYLE = {
  width: "400px",
  height: "400px",
};

const DEFAULT_CENTER = {
  lat: 23.0225,
  lng: 72.5714,
};

const DEFAULT_ZOOM = 10;

const MapComponent = (props: MapProps) => {
  const { containerStyle, center, zoom, children, mapRef, onCenter } = props;

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY as string,
  });

  const [map, setMap] = useState<google.maps.Map | null>(null);

  const onLoad = useCallback(function callback(map: google.maps.Map) {
    // const bounds = new window.google.maps.LatLngBounds(
    //   center || DEFAULT_CENTER
    // );
    // map.fitBounds(bounds);
    map.setZoom(zoom || DEFAULT_ZOOM);

    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      ref={mapRef}
      mapContainerStyle={containerStyle || DEFAULT_CONTAINER_STYLE}
      center={center || DEFAULT_CENTER}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{ fullscreenControl: false, mapTypeControl: false, zoom: zoom }}
      onCenterChanged={onCenter}
    >
      {children}
    </GoogleMap>
  ) : (
    <></>
  );
};

export default memo(MapComponent);
