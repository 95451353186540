import { useEffect, useState, CSSProperties, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Tabs,
  Box,
  Card,
  Grid,
  useMantineTheme,
  Avatar,
  Text,
  TextInput,
  Center,
} from "@mantine/core";
import { fetchAttendanceLog } from "../services/services";
import { debounce } from "../../../shared/utility";
import {
  LocationButton,
  LocationButtonAttendence,
} from "../../shared components/styledButton";
import { AttendenceCalender } from "../../shared components/attendenceCalender";
import { AttendenceCalenderMobile } from "../../shared components/attendenceCalenderMobile";
import { LocationModal } from "../../shared components/locationModal";
import MapComponent from "../../../component/common/map";
import LocationMarker from "../../roomLocation/container/locationMarker";
import { Loader } from "@mantine/core";
import TimeZoneDropdown from "../../shared components/timeZoneDropdown";
import useManageParameters from "../../../hooks/useManageParameters";
import useSaveParams from "../../../hooks/useSaveParams";

const containerStyle: CSSProperties = {
  width: "100%",
  height: "93%",
  borderRadius: "7px",
};

const tabs = {
  teamMember: "Team Members",
};

export default function AttendenceDashboard() {

  useSaveParams();
  const { workspace_id } = useManageParameters();
  const { name, mobile_no } = JSON.parse(localStorage.getItem("current_user"));
  const [selectedDate, setSelectedDate] = useState("");
  const [passingDate, setPassingDate] = useState<Date>();
  const [attendanceLog, setAttendanceLog] = useState<any>();
  const [userLocation, setUserLocation] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedTimeZone, setSelectedTimeZone] = useState("");

  const handleDateSelect = (date) => {
    setPassingDate(date);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    setSelectedDate(formattedDate);
  };
  const handleTimeZoneChange = (timezone) => {
    setSelectedTimeZone(timezone);
  };

  const changeHandler = (e: any) => {
    const { value } = e.target;
    setSearchText(value);
  };

  const filteredLogs = attendanceLog
    ? attendanceLog.filter((log) => {
        const name = log.name;
        const searchString = searchText.toLowerCase();
        return name.toLowerCase().includes(searchString);
      })
    : [];

  const [center, setCenter] = useState(
    filteredLogs?.location?.length
      ? {
          lat: parseFloat(filteredLogs?.location[0] || 0),
          lng: parseFloat(filteredLogs?.location[1] || 0),
        }
      : { lat: 23.57, lng: 72.7 }
  );

  const [actionLoading, setActionLoading] = useState(false);

  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState<string | null>("teamMember");
  const theme = useMantineTheme();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const fetchAttendance = () => {
    setIsLoading(true);
    if (workspace_id && selectedDate) {
      const params: any = {
        workspace_id,
        date: selectedDate,
        timezone: selectedTimeZone,
      };
      fetchAttendanceLog(params)
        .then((res: any) => {
          setIsLoading(false);
          setAttendanceLog(res);
        })
        .catch((error) => {
          console.log("Error : ", error);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (selectedDate) {
      fetchAttendance();
    }
  }, [selectedDate, selectedTimeZone]);

  const renderLocation = () => {
    return (
      <>
        <div className="hide-in-mobile">
          <LocationButton onClick={openModal} borderRadius="12px" />
        </div>
        <div className="show-in-mobile-only">
          <div className="location-btn-mobile">
            {" "}
            <LocationButtonAttendence
              borderRadius="4px"
              width="100%"
              onClick={openModal}
            />
          </div>
        </div>
        <LocationModal
          isOpen={isModalOpen}
          onClose={closeModal}
          width="1100px"
          height="500px"
          title=""
          value={""}
        >
          {/* MAP Component */}
          {filteredLogs?.location?.length === "" ? (
            <div className="location-not-available">
              <h3>Location Not Available</h3>
            </div>
          ) : (
            <MapComponent
              containerStyle={containerStyle}
              center={center}
              zoom={8}
            >
              <LocationMarker
                Center={
                  filteredLogs?.location
                    ? {
                        lat: filteredLogs?.location[0],
                        lng: filteredLogs?.location[1],
                      }
                    : center
                }
                imgPath={
                  filteredLogs?.profile_pic
                    ? filteredLogs?.profile_pic
                    : "https://www.nicepng.com/png/detail/186-1866063_dicks-out-for-harambe-sample-avatar.png"
                }
                isLive={false}
              />
            </MapComponent>
          )}
        </LocationModal>
      </>
    );
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const attendanceCards = isLoading ? (
    <Center style={{ minHeight: "calc(100vh - 386px)" }}>
      <Loader variant="bars" />
    </Center>
  ) : filteredLogs?.length ? (
    filteredLogs?.map((log, index) => (
      <Card
        key={log?.id}
        shadow="xs"
        p="lg"
        radius="md"
        withBorder
        style={{ marginTop: "10px" }}
      >
        <Grid>
          <Grid.Col md={6} lg={10}>
            <div className="mobile-no-box">
              <Avatar
                src={
                  log?.profile_pic ||
                  "https://www.nicepng.com/png/detail/186-1866063_dicks-out-for-harambe-sample-avatar.png"
                }
                alt="User Profile"
                className="avathar-box"
              />
              <Text weight={"bold"} size={"lg"} style={{ paddingLeft: "10px" }}>
                {log?.name}
              </Text>
            </div>
          </Grid.Col>
          <Grid.Col md={6} lg={2}>
            {renderLocation()}
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col md={6} lg={10}>
            <Text size={"sm"}>Working Hours</Text>
            <Text size={"sm"} weight={"bold"}>
              {log?.working_hrs || "_"}
            </Text>
          </Grid.Col>
          <Grid.Col md={6} lg={2}>
            <Text size={"sm"}>Check In-and-Out Time</Text>
            <Text size={"sm"} weight={"bold"}>
              {log.logs
                .map((logEntry) => {
                  const checkinTime = logEntry.checkin
                    ? new Date(logEntry.checkin).toLocaleTimeString("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: false,
                      })
                    : "**.**";

                  const checkoutTime = logEntry.checkout
                    ? new Date(logEntry.checkout).toLocaleTimeString("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: false,
                      })
                    : "**.**";

                  return `${checkinTime}-${checkoutTime}`;
                })
                .join(", ")}
            </Text>
          </Grid.Col>
        </Grid>
      </Card>
    ))
  ) : (
    <Center style={{ marginTop: "10px" }}>No Attendance Found</Center>
  );

  const attendanceCardsMobile = isLoading ? (
    <Center style={{ minHeight: "calc(100vh - 386px)" }}>
      <Loader variant="bars" />
    </Center>
  ) : filteredLogs?.length ? (
    filteredLogs?.map((log, index) => (
      <Card
        key={log?.id}
        p="lg"
        radius="md"
        withBorder
        style={{ borderColor: "#75B121", marginBottom: "5px" }}
        className="show-in-mobile-only"
      >
        <div className="mobile-rooms">
          <div className="mobile-no-box">
            <Avatar
              src={
                log?.profile_pic ||
                "https://www.nicepng.com/png/detail/186-1866063_dicks-out-for-harambe-sample-avatar.png"
              }
              alt="User Profile"
              className="avathar-box-mobile"
            />
            <Text weight={"bold"} size={"lg"}>
              {log?.name}
            </Text>
          </div>
          <Box className="mobile-rooms-inside">
            <Text size={"sm"} fw={500}>
              Working Hr.: {log?.working_hrs || "_"}
            </Text>
          </Box>
        </div>
        <div className="mobile-rooms-inside" style={{ marginTop: "10px" }}>
          <Box className="mobile-rooms-inside">
            <Text size={"sm"} fw={500}>
              Check In-and-Out Time
            </Text>
            <Text size={"sm"} weight={"bold"}>
              {log.logs
                .map((logEntry) => {
                  const checkinTime = logEntry.checkin
                    ? new Date(logEntry.checkin).toLocaleTimeString("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: false,
                      })
                    : "**.**";

                  const checkoutTime = logEntry.checkout
                    ? new Date(logEntry.checkout).toLocaleTimeString("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: false,
                      })
                    : "**.**";

                  return `${checkinTime}-${checkoutTime}`;
                })
                .join(", ")}
            </Text>
          </Box>
        </div>
        <div>{renderLocation()}</div>
      </Card>
    ))
  ) : (
    <Center className="show-in-mobile-only">No Attendance Found</Center>
  );

  return (
    <>
      <Card
        shadow="xs"
        p="lg"
        radius="md"
        withBorder
        style={{ textAlign: "left", minHeight: "calc(100vh - 185px)" }}
        className="hide-in-mobile"
      >
        <Box>
          <Tabs color="lime.9" value={activeTab} onTabChange={setActiveTab}>
            <Grid gutter={0} align="flex-end">
              <Grid.Col md={6} lg={3}>
                <Tabs.List>
                  <Tabs.Tab value="teamMember" style={{ fontWeight: "bold" }}>
                    {tabs["teamMember"]}
                  </Tabs.Tab>
                </Tabs.List>
              </Grid.Col>
              <Grid.Col
                md={6}
                lg={9}
                style={{
                  borderBottom: `2px solid ${
                    theme.colorScheme === "dark"
                      ? theme.colors.dark[4]
                      : theme.colors.gray[3]
                  }`,
                }}
              >
                <Grid my={"2px"} gutter={"xs"}>
                  <Grid.Col sm={6} md={8} lg={5}>
                    <TimeZoneDropdown onTimeZoneChange={handleTimeZoneChange} />
                  </Grid.Col>

                  <Grid.Col sm={6} md={8} lg={5}>
                    <AttendenceCalender
                      onDateSelect={handleDateSelect}
                      passingDate={passingDate}
                    />
                  </Grid.Col>
                  <Grid.Col sm={6} md={4} lg={2}>
                    <TextInput
                      value={searchText}
                      onChange={changeHandler}
                      icon={<i className="bx bx-search" />}
                    />
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            </Grid>
            {attendanceCards}
          </Tabs>
        </Box>
      </Card>
      <div className="show-in-mobile-only">
        <div style={{ marginBottom: "5px" }}>
          <TimeZoneDropdown onTimeZoneChange={handleTimeZoneChange} />
        </div>
        <div>
          <TextInput
            value={searchText}
            onChange={changeHandler}
            icon={<i className="bx bx-search" />}
            style={{ marginBottom: "8px" }}
          />
        </div>

        <Box className="attendence-datepicker">
          <AttendenceCalenderMobile
            onDateSelectMobile={handleDateSelect}
            passingDate={passingDate}
          />
        </Box>
      </div>
      {attendanceCardsMobile}
    </>
  );
}
