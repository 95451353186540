import axios, { AxiosResponse } from "axios";
import { getUrl } from "../../constant/constant";
import { GlobalObjectInterface } from "../interface";
// import AuthService from "./auth.service";
const axiosInstance = axios.create();
const CancelToken = axios.CancelToken;
let cancel_req: GlobalObjectInterface;

export { cancel_req };

export interface AxiosParams {
  method: string;
  url: string;
  data?: GlobalObjectInterface;
  isAccessTokenRequire?: boolean;
  contentType?: string;
  Authorization?: string;
}

export interface MiscellaneousRequestParams {
  isAccessTokenRequire?: boolean;
  contentType?: string;
  Authorization?: string;
}

/**
 * get method
 * @param request object containing axios params
 */
const get = (
  url: string,
  params: GlobalObjectInterface = {},
  otherData: MiscellaneousRequestParams = {}
) => {
  return commonAxios({ method: "GET", url: getUrl(url, params), ...otherData });
};

const patch = (
  url: string,
  params: GlobalObjectInterface = {},
  otherData: MiscellaneousRequestParams = {}
) => {
  return commonAxios({
    method: "PATCH",
    url: getUrl(url, params),
    ...otherData,
  });
};

/**
 * post method
 * @param request object containing axios params
 */
const post = (
  url: string,
  params: GlobalObjectInterface = {},
  queryParams = {},
  otherData: MiscellaneousRequestParams = {}
) => {
  return commonAxios({
    method: "POST",
    url: getUrl(url, queryParams),
    data: params,
    ...otherData,
  });
};

/**
 * put method
 * @param request object containing axios params
 */
const put = (
  url: string,
  params: GlobalObjectInterface = {},
  queryParams = {},
  otherData: MiscellaneousRequestParams = {}
) => {
  return commonAxios({
    method: "PUT",
    url: getUrl(url, queryParams),
    data: params,
    ...otherData,
  });
};

/**
 * deleteRequest method
 * @param request object containing axios params
 */
const deleteRequest = (
  url: string,
  params: GlobalObjectInterface = {},
  queryParams = {},
  otherData: MiscellaneousRequestParams = {}
) => {
  return commonAxios({
    method: "DELETE",
    url: getUrl(url, queryParams),
    data: params,
    ...otherData,
  });
};

/**
 * patch method
 * @param request object containing axios params
 */
// const patch = (
//   url: string,
//   params: GlobalObjectInterface = {},
//   otherData: MiscellaneousRequestParams = {}
// ) => {
//   return commonAxios({
//     method: "PATCH",
//     url: getUrl(url),
//     data: params,
//     ...otherData,
//   });
// };

/**
 * commonAxios
 * @param object containing method, url, data, access token, content-type
 */
const commonAxios = ({
  method,
  url,
  data,
  isAccessTokenRequire = true,
  contentType = "application/json",
  Authorization = "",
}: AxiosParams): Promise<GlobalObjectInterface> => {
  const headers: GlobalObjectInterface = {
    // "Content-Type": contentType,
  };
  const token =
    // isAccessTokenRequire
    isAccessTokenRequire &&
    (Authorization || localStorage.getItem("authToken"));

  if (token) {
    headers["Authorization"] = `${token}`;
  } /* else {
    headers["x-request-language"] = localStorage.getItem("lang") || "de";
  } */
  let body: string | GlobalObjectInterface | null = null;
  if (contentType === "application/json") {
    body = JSON.stringify(data);
  } else {
    body = data as GlobalObjectInterface;
  }

  return new Promise((resolve, reject) => {
    axiosInstance({
      method: method,
      url: url,
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel_req = c;
      }),
      headers: headers,
      data: body,
    })
      .then((response: AxiosResponse<GlobalObjectInterface>) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const httpService = {
  get: get,
  post: post,
  put: put,
  deleteRequest: deleteRequest,
  patch: patch,
  // patch: patch,
};

export { axiosInstance };

export default httpService;
