import React, { useEffect, useState } from "react";
import { Card, Grid, Button, Image, Text, Center, Loader } from "@mantine/core";
import PAYMENT from "../../../assets/images/payment.png";
import ELLIPSE from "../../../assets/images/Ellipse.png";
import CHECK from "../../../assets/images/Check.png";
import {
  fetchSubscriptions,
  fetchSubscriptionCoupons,
  changePlans,
  generatePayLink,
  fetchSpecificWorkspace,
} from "../services/services";
import { UserInterface } from "../../../shared/interface";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

const UpgradePlanModal = ({
  showUpgradeButton,
  openUpgradeModal,
  closeUpgradeModal,
  isSubscribing,
}) => {
  const workspace_id = localStorage.getItem("workspace_id");
  const mParam = localStorage.getItem("mParam");
  const [subscriptions, setSubscriptions] = useState<any[]>([]);
  const [selectedPlanId, setSelectedPlanId] = useState<string>("");
  const [coupons, setCoupons] = useState<any>();
  const [isCopied, setIsCopied] = useState(false);
  const [paylink, setPaylink] = useState("");
  const Paddle = window.Paddle;
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [paddleLoading, setPaddleLoading] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const [isOpenedWithLink, setIsOpenedWithLink] = useState(mParam !== null && mParam !== undefined);
  const [isLoading, setIsLoading] = useState(false);

  const localStorageSelectedWorkSpace =
    localStorage.getItem("selected_workspace");
  const selectedWorkspace =
    localStorageSelectedWorkSpace !== "undefined" &&
    localStorageSelectedWorkSpace !== null
      ? JSON.parse(localStorageSelectedWorkSpace)
      : null;

  useEffect(() => {
    const fetchData = () => {
      setLoading(true);
      fetchSubscriptions()
        .then((subscriptionsData: any) => {
          setSubscriptions(subscriptionsData?.plans);
        })
        .catch((error) => {
          console.error("Error fetching subscriptions:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedPlanId) {
      setIsLoading(true);
      fetchSubscriptionCoupons(selectedPlanId)
        .then((couponData: any) => {
          setCoupons(couponData || "No coupon available");
        })
        .catch((error) => {
          console.error("Error fetching subscription coupons:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [selectedPlanId]);

  const copyToClipboard = (text) => {
    const textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 5000);
  };

  const handlePlanSelection = (selectedPlanId: string | null) => {
    if (selectedPlanId) {
      generatePayLink(selectedWorkspace?.id, selectedPlanId)
        .then((res: any) => {
          setPaylink(res?.payment_link);
        })
        .catch((error) => {
          console.error("paylink is not fetched", error);
        });
    }
  };

  const handleButtonClick = () => {
    if (showUpgradeButton && paylink) {
      setPaddleLoading(true);
      Paddle.Checkout.open({
        override: paylink,
        successCallback: () => {
          setTimeout(() => {
            setPaddleLoading(false);
            fetchSpecificWorkspace(selectedWorkspace?.id)
              .then((res: any) => {
                closeUpgradeModal();
                localStorage.setItem("selected_workspace", JSON.stringify(res));
                toast.success("Successfully subscribed");
                if (isSubscribing) {
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                }
              })
              .catch((error) => {
                console.error("Workspace fetching failed");
              });
          }, 5000);
        },
      } as any);
    } else {
      setPaddleLoading(true);
      changePlans(workspace_id, selectedPlanId)
        .then((res) => {
          console.log("Save API call successful:", res);
          toast.success("Save successful!");
          closeUpgradeModal();
          window.location.reload();
          setPaddleLoading(false);
        })
        .catch((error) => {
          console.error("Error calling save API:", error);
          toast.error("Save failed. Please try again.");
        });
    }
  };

  const renderBusinessCard = () => {
    return subscriptions.map((subscription: UserInterface) => {
      const handleSelect = () => {
        setSelectedPlanId(
          subscription.id === selectedPlanId ? null : subscription.id
        );
        handlePlanSelection(
          subscription.id === selectedPlanId ? null : subscription.id
        );
      };
      const isSelected = subscription.id === selectedPlanId;
      return (
        <div
          key={subscription.id}
          className="business-plan-card"
          onClick={handleSelect}
        >
          {isSelected ? (
            <div style={{ height: "30px", width: "30px", padding: "1px" }}>
              <Image
                src={CHECK}
                width={"100%"}
                height={"100%"}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              />
            </div>
          ) : (
            <div className="unchecked" />
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "20px",
            }}
          >
            <Text weight={"bold"} size={"md"}>
              {subscription.name}
            </Text>
            <Text size={"sm"} color="#667085" fw={500}>
              {`$${subscription.price}`}/month
            </Text>
          </div>
        </div>
      );
    });
  };

  const renderCoupons = () => {
    if (selectedPlanId && coupons) {
      return (
        <div className="show-coupons">
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Text size={"xs"} fw={500}>
              {coupons?.description}:&nbsp;
            </Text>
            <Text size={"xs"} fw={"bold"}>
              {coupons?.coupon}&nbsp;
            </Text>
            <Text size={"xs"} fw={500}>
              -{coupons?.discount_percentage}% off
            </Text>
          </div>
          <Text
            size={"xs"}
            fw={500}
            onClick={() => copyToClipboard(coupons?.coupon)}
            style={{ cursor: "pointer" }}
          >
            {isCopied ? "Code copied!" : "Copy code"}
          </Text>{" "}
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <Grid style={{ display: "flex", flexDirection: "row" ,marginBottom:'10px'}}>
        {isOpenedWithLink ? (
          <Card shadow="xs" p="lg" withBorder h={"max-content"}>
            <Text fw={"bold"}>You have reached your free users limit.</Text>
          </Card>
        ) : (
          <>
            <Card shadow="xs" p="lg" withBorder h={"max-content"}>
              {selectedWorkspace?.free_to_invite ? (
                ""
              ) : (
                <Text fw={"bold"}>You have reached your free limit.</Text>
              )}
              <div style={{ display: "flex", flexDirection: "row" }}>
                {selectedWorkspace?.free_to_invite ? (
                  <Text fw={"bold"}>Choose Your&nbsp;</Text>
                ) : (
                  <>
                    <Text fw={"bold"}>Subscribe to a&nbsp;</Text>
                  </>
                )}
                <Text color="#75B121" fw={"bold"}>
                  Talker
                </Text>
                &nbsp;
                <Text fw={"bold"}>Plan per seat per</Text>
              </div>
              <Text fw={"bold"}>month.</Text>
              {loading ? (
                <Center>
                  <Loader variant="bars" />
                </Center>
              ) : (
                renderBusinessCard()
              )}
              {isLoading ? (
                <Center>
                  <Loader variant="bars" />
                </Center>
              ) : (
                renderCoupons()
              )}
              {paddleLoading ? (
                <Center>
                  <Loader variant="bars" />
                </Center>
              ) : (
                <Button
                  bg={"#75B121"}
                  fullWidth
                  mt={"40px"}
                  mb={"40px"}
                  onClick={() => {
                    handleButtonClick();
                  }}
                >
                  {selectedWorkspace?.free_to_invite
                    ? showUpgradeButton
                      ? "Continue"
                      : "Save"
                    : "Get Started"}
                </Button>
              )}
              <div
                className="show-in-mobile-only"
                style={{ marginBottom: "10px" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "5px",
                  }}
                >
                  <Image
                    src={ELLIPSE}
                    width={30}
                    height={30}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "10px",
                    }}
                  />
                  <Text fw={"500"}>Parag Arora</Text>
                </div>
                <Text size={"sm"} fw={"500"}>
                  Get direct customer support over email and Whatsapp Business
                  plan users
                </Text>
              </div>
              <Text size={"xs"} fw={400} underline>
                Know more about pricing plans here
              </Text>
            </Card>
            <Card
              bg={"#FBFBFB"}
              w={"50%"}
              h={"max-content"}
              className="hide-in-mobile"
            >
              <Image src={PAYMENT} width={350} height={320} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "5px",
                }}
              >
                <Image
                  src={ELLIPSE}
                  width={30}
                  height={30}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "10px",
                  }}
                />
                <Text fw={"500"}>Parag Arora</Text>
              </div>
              <Text size={"sm"} fw={"500"}>
                Get direct customer support over email and Whatsapp Business
                plan users
              </Text>
            </Card>
          </>
        )}
      </Grid>
    </>
  );
};

UpgradePlanModal.propTypes = {
  showUpgradeButton: PropTypes.bool.isRequired,
  isSubscribing: PropTypes.bool.isRequired,
  openUpgradeModal: PropTypes.func.isRequired,
  closeUpgradeModal: PropTypes.func.isRequired,
};

export default UpgradePlanModal;
