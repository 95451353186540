import {
  Avatar,
  Box,
  Button,
  Card,
  Center,
  Dialog,
  Grid,
  Input,
  Loader,
  Paper,
  Text,
  TextInput,
} from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import { bindActionCreators } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { action } from "../../../hoc/redux/reducers";

import { fetchUserCount } from "../service/settingService";
import settingStyle from "./settingStyle.module.css";
import httpService from "../../../shared/services/http.service";
import {
  GlobalObjectInterface,
  UserInterface,
} from "../../../shared/interface";
import { fetchUserList } from "../../users/services/services";
import { useForm } from "@mantine/form";
import {
  API_CONFIG,
  EMAIL_REGEX,
  getFileUrl,
} from "../../../constant/constant";
import { debounce } from "lodash";
import toast, { Toaster } from "react-hot-toast";
import { Bold } from "tabler-icons-react";

interface Props {
  actionLoading: boolean;
  onSubmit: (values: GlobalObjectInterface) => void;
}
const ChannelSettings = () => {
  const currentUser = JSON.parse(localStorage.getItem("current_user"));
  const mobileNo = currentUser.mobile_no.slice(2);
  const workspace_id = localStorage.getItem("workspace_id");
  const [users, setUsers] = useState<UserInterface[]>([]);
  const [numOfUsr, setNumOfUsr] = useState(0);
  const [currentWorkspace, setCurrentWorkSpace] = useState<any>({});
  const [incompleteBusinessProfile, setShowUpgradeBool] = useState(
    currentWorkspace?.plan_id === null
  )
  const [isLoading, setLoading] = useState(true);
  const [file, setFile] = useState<File | null>();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [emailError, setEmailError] = useState("");
  const authToken = localStorage.getItem("authToken");
  const mParam = localStorage.getItem("mParam");
  const [isOpenedinApp, setIsOpenedinApp] = useState(mParam !== null && mParam !== undefined);

  const dispatch = useDispatch();
  const { DisableWorkSpace, unableWorkSpace, DeleteworkspaceReq } =
    bindActionCreators(action, dispatch);
  const allWorkSpaces =
    useSelector((state: any) => state.userWorkSpaceReducer.workspaces) || [];
  const [actionLoading, setActionLoading] = useState(false);

  const showToast = debounce((message) => {
    toast.success(message);
  }, 1000);

  const validateEmail = (email) => {
    return EMAIL_REGEX.test(email);
  };

  const onSubmit = async () => {
    setActionLoading(true);
    setFormSubmitted(true);

    if (!currentWorkspace.name || !currentWorkspace.email) {
      toast.error("Both fields are mandatory for submission");
      setActionLoading(false);
      return;
    }
    if (formSubmitted && !validateEmail(currentWorkspace.email)) {
      setEmailError("Please enter a valid email address");
      setActionLoading(false);
      return;
    } else {
      setEmailError("");
    }
    delete currentWorkspace.phone;
    delete currentWorkspace.designation;
    if (!currentWorkspace.icon_changed) {
      delete currentWorkspace.icon;
      delete currentWorkspace.icon_changed;
    }
    try {
      const formData = new FormData();
      for (const key in currentWorkspace) {
        if (key === "icon" && currentWorkspace.icon instanceof File) {
          formData.append(key, currentWorkspace.icon);
        } else {
          formData.append(key, currentWorkspace[key]);
        }
      }
      formData.append("workspace_id", localStorage.getItem("workspace_id"));
      const response = await httpService.put(
        API_CONFIG.path.fetchWorkSpaces,
        formData,
        {},
        { contentType: "multipart/form-data" }
      );
      const { workspace } = response;
      setActionLoading(false);
      localStorage.setItem("selected_workspace", JSON.stringify(workspace));  
      toast.success("Workspace profile updated successfully");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      setActionLoading(false);
      console.error("Error:", error);
    }
  };

  const handleWorkspaceDisableFunc = () => {
    if (currentWorkspace.active) {
      const statusUpdatedWorkspaces = allWorkSpaces.map((workspace: any) => {
        if (workspace.id === currentWorkspace.id) {
          return { ...workspace, active: false };
        } else {
          return { ...workspace };
        }
      });
      DisableWorkSpace(statusUpdatedWorkspaces);
      setCurrentWorkSpace({ ...currentWorkspace, active: false });
    } else {
      const statusUpdatedWorkspaces = allWorkSpaces.map((workspace: any) => {
        if (workspace.id === currentWorkspace.id) {
          return { ...workspace, active: true };
        } else {
          return { ...workspace };
        }
      });
      unableWorkSpace(statusUpdatedWorkspaces);
      setCurrentWorkSpace({ ...currentWorkspace, active: true });
    }
  };

  useEffect(() => {
    const workspace_id = localStorage.getItem("workspace_id");
    if (
      (allWorkSpaces.length > 0 && workspace_id === null) ||
      workspace_id === "undefined"
    ) {
      localStorage.setItem(
        "workspace_id",
        JSON.parse(localStorage.getItem("selected_workspace") as any).id
      );
    }
    if (allWorkSpaces.length > 0) {
      const updateWorkspace = allWorkSpaces.find(
        (workspace: any) =>
          workspace.id === localStorage.getItem("workspace_id")
      );
      setCurrentWorkSpace(updateWorkspace);
      fetchNumberOfuser();
      setLoading(false);
    }
  }, [allWorkSpaces]);
  useEffect(() => {
    setShowUpgradeBool(currentWorkspace?.plan_id === null);
  }, [currentWorkspace]);


  const fetchNumberOfuser = () => {
    const workspace_id = localStorage.getItem("workspace_id");
    const perams = {
      workspace_id,
    };
    fetchUserCount(perams)
      .then((count: any) => {
        setNumOfUsr(count);
        setLoading(false);
      })
      .catch((error) => console.log("error :", error));
  };

  const navigate = useNavigate();
  const confirmDelete = () => {
    openConfirmModal({
      title: <b>Confirmation!</b>,
      children: (
        <Text size="sm">
          Are you sure you want to remove the selected workspace?
        </Text>
      ),
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onConfirm: async () => {
        try {
          await DeleteworkspaceReq(allWorkSpaces);
          showToast("Deleted workspace successfully");
          if (authToken) {
            navigate("/workspaces", {
              state: {
                auth_token: authToken,
              },
            });
          }
        } catch (error) {
          console.error("Error deleting workspace:", error);
          showToast("Failed to delete workspace");
        }
      },
    });
  };

  const fetchUsers = (search = "") => {
    if (workspace_id) {
      const params: GlobalObjectInterface = {
        page: 1,
        workspace_id,
      };

      if (search) {
        params.search = search;
      }

      fetchUserList(params)
        .then((users: any) => {
          setUsers(users);
        })
        .catch((error) => {
          console.log("Error : ", error);
        });
    }
  };

  useEffect(fetchUsers, []);

  const getRole = () => {
    const currentUserPhoneNumber = currentUser.mobile_no;
    const matchingUser = users.find(
      (user) => user.mobile_no === currentUserPhoneNumber
    );
    const role = matchingUser ? matchingUser.role : "Role Not Found";
    return role;
  };

  return isLoading ? null : (
    <>
  {isOpenedinApp ? null:
    
   <Grid style={{ padding: '20px' }}>
   
   <Text style={{ fontWeight: 'bold',padding: '10px' }}>
      To complete the business profile of this workplace 
      </Text>

    <Button style={{padding: '10px'}} onClick={()=> navigate("/workspaces/dashboard/plan-and-billing")}
    variant="outline"
    color={"blue"}>
    go to Plan and Billing

    </Button>
    </Grid>
}
      <Card
        shadow="xs"
        p="lg"
        radius="md"
        withBorder
        style={{ minHeight: "calc(75vh - 185px)" }}
      >
        <Toaster toastOptions={{ duration: 3000 }} />
        <Grid align={"center"} justify="center" grow gutter="xl">
          <Grid.Col lg={5} md={12} sm={12} p={"20px"}>
            <Text size="sm" style={{ marginBottom: "8px" }}>
              Your Role
            </Text>
            <Input
              placeholder="New Role"
              mb={"20px"}
              disabled
              value={getRole()}
            />
            <Text size="sm" style={{ marginBottom: "8px" }}>
              Workspace Name
            </Text>
            <Input
              placeholder="Workspace Name"
              mb={"20px"}
              value={currentWorkspace.name}
              onChange={(event) => {
                setCurrentWorkSpace({
                  ...currentWorkspace,
                  name: event.currentTarget.value,
                });
              }}
            />
            <Text size="sm" style={{ marginBottom: "8px" }}>
              Workspace Email Address
            </Text>
            <Input
              placeholder="Email"
              value={currentWorkspace.email}
              onChange={(event) => {
                setCurrentWorkSpace({
                  ...currentWorkspace,
                  email: event.currentTarget.value,
                });
                setEmailError("");
              }}
              autoFocus
              type={"email"}
              className={emailError ? "input-error" : ""}
            />
            {emailError && <div className="error-message">{emailError}</div>}
          </Grid.Col>
          <Grid.Col lg={5} md={12} sm={12} p={"20px"}>
            <Box>
              <Center>
                <Avatar
                  id="avatar-image"
                  size={100}
                  radius={100}
                  src={
                    currentWorkspace.icon_preview_url ||
                    getFileUrl(file) ||
                    currentWorkspace.icon
                  }
                />
                <input
                  type="file"
                  id="avatar-input"
                  accept="image/*"
                  onChange={({ target }: GlobalObjectInterface) => {
                    const newFile = (target || {}).files[0];
                    setCurrentWorkSpace((prevWorkspace) => {
                      const updatedWorkspace = {
                        ...prevWorkspace,
                        icon: newFile,
                        icon_changed: true,
                      };
                      const avatarImage = document.getElementById(
                        "avatar-image"
                      ) as HTMLImageElement;
                      if (avatarImage && newFile) {
                        const imageUrl = URL.createObjectURL(newFile);
                        avatarImage.src = imageUrl;
                        setCurrentWorkSpace((prevWorkspace) => ({
                          ...prevWorkspace,
                          icon_preview_url: imageUrl,
                        }));
                      }
                      return updatedWorkspace;
                    });
                  }}
                  hidden
                />

                <Paper
                  className="edit-icon"
                  shadow="xl"
                  radius="xl"
                  style={{
                    width: "max-content",
                    height: "max-content",
                    padding: "0",
                  }}
                  withBorder
                >
                  <label
                    htmlFor="avatar-input"
                    className="flex justify-center align-center"
                    style={{ width: "30px", height: "30px" }}
                  >
                    <i className="bx bx-edit-alt" />
                  </label>
                </Paper>
              </Center>
            </Box>
          </Grid.Col>
        </Grid>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            marginTop: "50px",
          }}
        >
          <Button
            mr={"20px"}
            bg={"none"}
            variant="default"
            w={"200px"}
            // onClick={() => form.reset()}
          >
            Cancel
          </Button>
          <Button
           // bg={"#75b121"}
           // variant="gradient"
           variant="outline"
          color={"green"}
            w={"200px"}
            onClick={onSubmit}
            style={{ position: "relative" }}
          >
            {actionLoading && (
              <Loader variant="bars" color="white" size={"sm"} mr={"10px"} />
            )}
            Submit
          </Button>
        </div>
      </Card>

      <div className="workspace-buttons hide-in-mobile">
     
        <Button size="lg" onClick={confirmDelete} className="workspace-delete"
          >
          Delete Workspace
        </Button>

        <Button
          size="lg"
          bg={currentWorkspace.active ? "yellow" : "green"}
          onClick={handleWorkspaceDisableFunc}
          className="workspace-disable"
        >
          {!currentWorkspace.active
            ? "Activate Workspace"
            : "Disable Workspace"}
        </Button>
      </div>
      <div className="show-in-mobile-only">
        <div className="mobile-workspace">
          <Button
            size="lg"
            onClick={confirmDelete}
            className="workspace-delete-mobile"
          >
            Delete Workspace
          </Button>
          <Button
            size="lg"
            bg={currentWorkspace.active ? "yellow" : "green"}
            onClick={handleWorkspaceDisableFunc}
            className="workspace-disable-mobile"
          >
            {!currentWorkspace.active
              ? "Activate Workspace"
              : "Disable Workspace"}
          </Button>
        </div>
      </div>
    </>
  );
};
export default ChannelSettings;
