import { Button, Card, Center, Grid, Input, Loader, Text } from "@mantine/core";
import CustomModal from "../../../component/common/modal";
import { useEffect, useState } from "react";
import UpgradePlanModal from "../../users/component/upgradePlanModal";
import { openConfirmModal } from "@mantine/modals";
import {
  cancelSubscriptionPlans,
  fetchSpecificWorkspace,
  updateBilling,
} from "../../users/services/services";
import toast, { Toaster } from "react-hot-toast";
import { debounce } from "lodash";

const PlanAndBilling = () => {
  const workspace_id = localStorage.getItem("workspace_id");
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
  const [cancelingSubscription, setCancelingSubscription] = useState(false);
  const Paddle = window.Paddle;
  const [updatePaylink, setUpdatePaylink] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSubscribing, setIsSubscribing] = useState(false);
  const localStorageSelectedWorkSpace =
    localStorage.getItem("selected_workspace");
  const [selectedWorkspace, setSelectedWorkspace] = useState(localStorageSelectedWorkSpace !== "undefined" &&
  localStorageSelectedWorkSpace !== null
    ? JSON.parse(localStorageSelectedWorkSpace)
    : null);
  const [showUpgradeButton, setShowUpgradeButton] = useState(
    selectedWorkspace?.plan_id === null
  );

  useEffect(() => {
    setShowUpgradeButton(selectedWorkspace?.plan_id === null);
  }, [selectedWorkspace]);

  useEffect(() => {
    fetchSpecificWorkspace(workspace_id).then((res: any) => {
      setSelectedWorkspace(res?.workspace);
    });
  }, []);

  const showToast = debounce((message) => {
    toast.error(message);
  }, 1000);

  const openUpgradeModal = () => {
    setIsUpgradeModalOpen(true);
  };

  const closeUpgradeModal = () => {
    setIsUpgradeModalOpen(false);
  };

  const cancelSubscription = (workspace_id: string) => {
    let reloadTimeoutId: NodeJS.Timeout;
    openConfirmModal({
      title: <b>Confirmation!</b>,
      children: <Text size="sm">Do you want to cancel subscription?</Text>,
      labels: { confirm: "Yes", cancel: "No" },
      confirmProps: { color: "red" },
      onConfirm: async () => {
        try {
          await cancelSubscriptionPlans(workspace_id);
          await toast.success("Subscription cancelled!");
          reloadTimeoutId = setTimeout(() => {
            window.location.reload();
          }, 1000);
        } catch (error) {
          showToast("Something went wrong with the server");
          console.error("Error canceling subscription:", error);
        }
        setLoading(false);
      },
      onClose: () => {
        clearTimeout(reloadTimeoutId);
        setLoading(false);
      },
    });
  };
  

  const updateBillingButton = async (workspace_id: any) => {
    if (workspace_id) {
      setLoading(true);
      try {
        const res: any = await updateBilling(workspace_id);
        setUpdatePaylink(res?.payment_update_url);
        if (res?.payment_update_url) {
          Paddle.Checkout.open({
            override: res?.payment_update_url,
            successCallback: () => {
              closeUpgradeModal();
              toast.success("Successfully updated")
            },
          } as any);
        }
      } catch (error) {
        console.error("Update billing error:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  return (
    <Card
      shadow="xs"
      p="lg"
      radius="md"
      withBorder
      style={{ minHeight: "calc(75vh - 185px)" }}
    >
      <Toaster toastOptions={{ duration: 3000 }} />
      <CustomModal
        open={isUpgradeModalOpen}
        className="upgrade-modal"
        centered
        closeModal={closeUpgradeModal}
      >
        <UpgradePlanModal
          showUpgradeButton={showUpgradeButton}
          openUpgradeModal={openUpgradeModal}
          closeUpgradeModal={closeUpgradeModal}
          isSubscribing={isSubscribing}
        />
      </CustomModal>
      <Grid
        align={"center"}
        justify="center"
        grow
        gutter="xl"
        style={{ width: "60%" }}
      >
        <Grid.Col>
          <Text size="sm" style={{ marginBottom: "8px" }}>
            Plan Name
          </Text>
          <Input
            placeholder="New Plan"
            mb={"20px"}
            value={showUpgradeButton ? "No Plan" : selectedWorkspace?.plan ?? ""}
          />
          <Button
            className="upgrade-button"
            fullWidth
            onClick={() => {
              openUpgradeModal();
              if (showUpgradeButton) {
                setIsSubscribing(true);
              }
            }}
          >
            {showUpgradeButton ? "Subscribe Plan" : "Change Plan"}
          </Button>
          {showUpgradeButton && (
            <Text size="sm" style={{ marginBottom: "8px",color:"red" }}>
              Subscribe to a plan to complete business profile.
            </Text>
          )}
          {!showUpgradeButton && (
            <Button
              className="update-button"
              fullWidth
              onClick={() => updateBillingButton(selectedWorkspace?.id)}
            >
              Update Billing Details
            </Button>
          )}
          {!showUpgradeButton && (
            <Button
              style={{
                backgroundColor: "white",
                border: "1px solid #E80505",
                color: "#E80505",
              }}
              fullWidth
              onClick={() => {
                cancelSubscription(selectedWorkspace?.id);
                setLoading(true);
              }}
              disabled={cancelingSubscription}
            >
              {cancelingSubscription ? "Canceling..." : "Cancel Plan"}
            </Button>
          )}
        </Grid.Col>
      </Grid>
      {loading && (
        <Center style={{ minHeight: "calc(100vh - 386px)" }}>
          <Loader variant="bars" />
        </Center>
      )}
    </Card>
  );
};
export default PlanAndBilling;
