// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".locationStyle_scroll__Udu2-::-webkit-scrollbar {\n    display: none;\n\n}\n\n/* Hide scrollbar for IE, Edge and Firefox */\n.locationStyle_scroll__Udu2- {\n    -ms-overflow-style: none;\n    /* IE and Edge */\n    scrollbar-width: none;\n    /* Firefox */\n}", "",{"version":3,"sources":["webpack://./src/features/roomLocation/style/locationStyle.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;;AAEjB;;AAEA,4CAA4C;AAC5C;IACI,wBAAwB;IACxB,gBAAgB;IAChB,qBAAqB;IACrB,YAAY;AAChB","sourcesContent":[".scroll::-webkit-scrollbar {\n    display: none;\n\n}\n\n/* Hide scrollbar for IE, Edge and Firefox */\n.scroll {\n    -ms-overflow-style: none;\n    /* IE and Edge */\n    scrollbar-width: none;\n    /* Firefox */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scroll": "locationStyle_scroll__Udu2-"
};
export default ___CSS_LOADER_EXPORT___;
