import React from "react";
import { MantineTheme } from "@mantine/core";
import queryString from "query-string";

import { GlobalObjectInterface } from "../shared/interface";
export const API_CONFIG = {
   baseUrl: `${process.env.REACT_APP_BASE_URL}`,
  path: {
    fetchWorkSpaces: "dashboard/workspaces",
    user: "workspace_user",
    channelList: "dashboard/channels",
    updateChannel: "dashboard/channel",
    createChannel: "chat/channel",
    createSession: "create_session",
    MessageHistory: "workspaces/messages",
    userLocation: "workspace_user/location",
    usercount: "dashboard/workspaces/user_count",
    fetchAttendanceLog:"dashboard/workspaces/attendance",
    fetchAttendance:"dashboard/workspaces/user/attendance",
    dashboardLogin:"dashboard/login",
    inviteLink:"dashboard/workspace/invite",
    fetchSubscriptions:"dashboard/subscriptions",
    fetchSubscriptionCoupons:"dashboard/subscriptions/coupons",
    cancelSubscriptions:"dashboard/cancel_subscription",
    updateBilling:"dashboard/subscriptions/update_billing",
    changePlan:"dashboard/subscriptions/change_plan",
    generatePaymentLink:"dashboard/payment_link"
  },
};
export const getUrl = (
  url: string,
  params: GlobalObjectInterface = {}
): string => {
  let urlString = `${API_CONFIG.baseUrl}/${url}`;
  if (params && Object.keys(params).length > 0) {
    urlString += `?${queryString.stringify(params)}`;
  }
  return urlString;
};

export const getFileUrl = (file: File | null | undefined) => {
  if (file === null || file === undefined) return;
  const url = URL.createObjectURL(file);
  console.log(url);

  return url;
};

export const customStyles = (theme: MantineTheme) => ({
  menu: (base: React.CSSProperties) => ({
    ...base,
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[5]
        : theme.colors.gray[0],
  }),
  control: (base: React.CSSProperties) => ({
    ...base,
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[5]
        : theme.colors.gray[0],
  }),
  input: (base: React.CSSProperties) => ({
    ...base,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.gray[0]
        : theme.colors.dark[5],
  }),
});

export const EMAIL_REGEX = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;

export const PHONE_REGEX = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;

export const roomStatusMapper: { [key: number]: string } = {
  0: "Non Live",
  1: "Live",
};
